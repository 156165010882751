import { useState, useEffect, useCallback } from "react";

export const useTimeAgo = (groupDateWrapper: { groupDate: string | undefined }) => {
  const computeTimeAgo = useCallback(() => {
    const groupDate = groupDateWrapper.groupDate;
    if (!groupDate) return "-";
    const date = new Date(groupDate);
    const now = new Date();
    const secondsElapsed = Math.floor((now.getTime() - date.getTime()) / 1000);
    const minutesElapsed = Math.floor(secondsElapsed / 60);
    const hoursElapsed = Math.floor(minutesElapsed / 60);
    const daysElapsed = Math.floor(hoursElapsed / 24);

    if (daysElapsed > 0) {
      return `${daysElapsed}d`;
    } else if (hoursElapsed > 0) {
      return `${hoursElapsed}h`;
    } else if (minutesElapsed > 0) {
      return `${minutesElapsed}m`;
    } else {
      return `${secondsElapsed}s`;
    }
  }, [groupDateWrapper]);

  const [timeAgo, setTimeAgo] = useState(computeTimeAgo);

  useEffect(() => {
    const updateFrequency = () => {
      const { groupDate } = groupDateWrapper;
      if (!groupDate) return 86400000;
      const secondsElapsed = Math.floor(
        (new Date().getTime() - new Date(groupDate).getTime()) / 1000
      );
      if (secondsElapsed < 60) {
        // Less than a minute ago, update every second
        return 1000;
      } else if (secondsElapsed < 3600) {
        // Less than an hour ago, update every minute
        return 60000;
      } else if (secondsElapsed < 86400) {
        // Less than a day ago, update every hour
        return 3600000;
      } else {
        // More than a day ago, update less frequently, e.g., every 24 hours
        return 86400000;
      }
    };

    setTimeAgo(computeTimeAgo());

    const interval = setInterval(() => {
      setTimeAgo(computeTimeAgo());
    }, updateFrequency());

    return () => clearInterval(interval);
  }, [groupDateWrapper, computeTimeAgo]);

  return timeAgo;
};

export default useTimeAgo;
