import { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";
import { cn } from "~/utils/cn";

export const BlurHashImage: React.FC<{
  width: number;
  height: number;
  src?: string | undefined | null;
  blurHash?: string | undefined | null;
}> = ({ width, height, src, blurHash }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    if (!src) return;
    img.src = src;
    img.onload = () => setImageLoaded(true);
  }, [src]);

  return (
    <div className={cn(`flex-shrink-0`)} style={{ width, height }}>
      {!imageLoaded && blurHash && <Blurhash hash={blurHash} width={width} height={height} />}
      <img
        src={src ?? "/assets/images/avatar.png"}
        loading="lazy"
        className={cn(
          `bg-tertiary-accent bg-cover rounded-xl`,
          imageLoaded || !blurHash ? "visible" : "hidden"
        )}
        style={{ width, height }}
        alt="loaded content"
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};
